import { Camera as CameraIcon, UploadFile as UploadFileIcon } from '@mui/icons-material';
import { Button, Checkbox, DialogContent, Divider, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { KeyboardKey } from 'src/constants/ui';
import useEventListener from 'src/hooks/use-event-listener';
import { setFileElementUploader } from 'src/slices/ui';
import { useDispatch } from 'src/store';

import { DialogContainer } from '../common/dialog-container';

export type FORM_ELEMENT_UPLOADER = 'FILE' | 'CAM';

export const usePickFormElementUploader = () => {
    const [uploader, setUploader] = useState<FORM_ELEMENT_UPLOADER>(null);
    const [callbacks, setCallbacks] = useState({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleSelectUploader = (newUploader: FORM_ELEMENT_UPLOADER, rememberChoice: boolean) => {
        setIsOpen(false);
        if (rememberChoice) {
            setUploader(newUploader);
            dispatch(setFileElementUploader(newUploader));
        } else {
            setUploader(null);
        }
        if (callbacks[newUploader]) {
            callbacks[newUploader]();
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleOpenDialog = ({ fileCallback, camCallback }: { fileCallback: () => void; camCallback?: () => void }) => {
        setIsOpen(true);
        setCallbacks({ FILE: fileCallback, CAM: camCallback });
    };

    return {
        uploader,
        onOpen: handleOpenDialog,
        Component: <PickUploaderDialog
            onCancel={handleCancel}
            onSelect={handleSelectUploader}
            isOpen={isOpen} />
    }
}

type Props = {
    onSelect: (uploader: FORM_ELEMENT_UPLOADER, rememberChoice: boolean) => void;
    onCancel: () => void;
    isOpen?: boolean;
}

const PickUploaderDialog = ({ onSelect, onCancel, isOpen }: Props) => {
    const [rememberChoice, setRememberChoice] = useState<boolean>(false);

    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRememberChoice(checked);
    }

    const handleSelectCam = () => {
        onSelect('CAM', rememberChoice);
    }

    const handleSelectFile = () => {
        onSelect('FILE', rememberChoice);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === KeyboardKey.ENTER && isOpen) {
            handleSelectFile();
            onCancel?.();
        }
    }

    useEventListener('keydown', handleKeyDown);

    return (<DialogContainer open={isOpen} onClose={onCancel}>
        <DialogContent sx={{ justifyContent: 'space-between', display: 'flex', gap: 1 }}>
            <Button variant='contained' color='secondary' startIcon={<CameraIcon />}
                onClick={handleSelectCam}>
                Camera capture
            </Button>
            <Button variant='contained' color='primary' startIcon={<UploadFileIcon />}
                onClick={handleSelectFile}>
                Upload from device
            </Button>
        </DialogContent>
        <Divider />
        <DialogContent>
            <FormControlLabel
                sx={{
                    mx: 0,
                    width: '100%',
                    flexDirection: 'row-reverse',
                }}
                control={<Checkbox onChange={handleChange} />}
                label="Remember this choice, you can change it later in the settings." />
        </DialogContent>
    </DialogContainer>
    );
}