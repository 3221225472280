import { Camera, Cancel, Check, Close, Upload } from "@mui/icons-material";
import type { Theme } from '@mui/material';
import {
    AppBar,
    Box,
    Card,
    CircularProgress,
    Dialog,
    Fab,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Slide,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material"
import { TransitionProps } from '@mui/material/transitions';
import imageCompression from 'browser-image-compression';
import { jsPDF } from "jspdf"; // will automatically load the node version
import { forwardRef, TransitionEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DropEvent, FileRejection } from "react-dropzone";
import Webcam from "react-webcam";
import { JestTestId } from "src/constants/ui";
import { useImageProcessing } from "src/hooks/use-image-processing";
import { uiSelector } from "src/slices/ui";
import { useSelector } from "src/store";
import { getFormattedDevicesList } from "src/utils/cam-scan";

const portraitConstraints = {
    width: { min: 640, ideal: 1920 },
    height: { min: 480, ideal: 1440, max: 1440 }
};

const landscapeConstraints = {
    width: { min: 960, ideal: 1024, max: 1024 },
    height: { min: 720, ideal: 720, max: 1024 },
};

const initialState = {
    deviceId: null,
    camError: null,
    isCamReady: false,
    approvalScreenshot: null,
    isApprovalDialogVisible: false,
    isUploading: false,
    screenshots: [],
    isLoading: false
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_CAM_DEVICE_ID':
            return { ...state, deviceId: action.payload };
        case 'SET_CAM_READY':
            return { ...state, isCamReady: true };
        case 'SET_CAM_ERROR':
            return { ...state, camError: action.payload };
        case 'SET_APPROVAL_SCREENSHOT':
            return { ...state, approvalScreenshot: action.payload };
        case 'SET_APPROVAL_DIALOG_VISIBLE':
            return { ...state, isApprovalDialogVisible: action.payload };
        case 'SET_IS_UPLOADING':
            return { ...state, isUploading: action.payload };
        case 'SET_SCREENSHOTS':
            return { ...state, screenshots: [...state.screenshots, action.payload] };
        default:
            return state;
    }
}

type Props = {
    onUpload: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent, skipConfirmation: boolean) => void;
    onClose: () => void;
    title: string;
}

export const FormElementCamCapture = (props: Props) => {
    const { onUpload, onClose, title } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    const webcamRef = useRef(null);

    const { getBlackAndWhiteImageDataURI } = useImageProcessing();
    const { camScanMode } = useSelector(uiSelector);
    const { devices, isPortrait } = useMediaDevices();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleCaptureScreenshot = useCallback(async () => {
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        const imageSrc = webcamRef.current.getScreenshot();
        const blackAndWhiteImage = camScanMode === 'COLOR' ? imageSrc : await getBlackAndWhiteImageDataURI(imageSrc);
        dispatch({ type: 'SET_APPROVAL_SCREENSHOT', payload: blackAndWhiteImage });
        dispatch({ type: 'SET_APPROVAL_DIALOG_VISIBLE', payload: true });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
    }, [camScanMode, getBlackAndWhiteImageDataURI]);

    const handleGeneratePdf = useCallback(async () => {
        const doc = new jsPDF({
            orientation: isPortrait ? 'portrait' : 'landscape',
        });

        await Promise.all(state.screenshots.map((imageSrc, index) => {
            return new Promise(async (resolve) => {
                // get width/height from base64 image
                const image = new Image();
                image.src = imageSrc;
                image.onload = () => {
                    const { width: imgWidth, height: imgHeight } = image;

                    const width = doc.internal.pageSize.getWidth();
                    const height = doc.internal.pageSize.getHeight();
                    // get page width and height landscape or portrait
                    const imgAspectRatio = imgWidth / imgHeight;
                    const imgWidthFit = width;
                    const imgHeightFit = imgWidthFit / imgAspectRatio;

                    doc.addImage(imageSrc, 'JPEG', 0, (height - imgHeightFit) / 2, imgWidthFit, imgHeightFit, undefined, 'NONE');
                    resolve(true);
                    if (index < state.screenshots.length - 1) {
                        doc.addPage('a4', isPortrait ? 'portrait' : 'landscape');
                    }
                };
            })
        }));

        // create blob from buffer
        const blob = doc.output('blob');
        // convert blob to File
        const file = new File([blob], `${title}.pdf`, { type: 'application/pdf' });
        // upload file
        dispatch({ type: 'SET_IS_UPLOADING', payload: true });
        onUpload([file], [], null, true);
        dispatch({ type: 'SET_IS_UPLOADING', payload: false });
        handleClose();
    }, [isPortrait, state.screenshots, title, onUpload, handleClose]);

    const handleApproveScreenshot = useCallback(async () => {
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        const imageFile = await imageCompression.getFilefromDataUrl(state.approvalScreenshot, `${title}.pdf`);

        const options = {
            maxSizeMB: .5,
            initialQuality: 1,
            maxWidthOrHeight: 1280,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            const imageDataUrl = await imageCompression.getDataUrlFromFile(compressedFile);
            dispatch({ type: 'SET_SCREENSHOTS', payload: imageDataUrl });
        } catch (error) {
            console.error({ error });
            dispatch({ type: 'SET_SCREENSHOTS', payload: state.approvalScreenshot });
        }
        dispatch({ type: 'SET_APPROVAL_DIALOG_VISIBLE', payload: false });
        dispatch({ type: 'SET_IS_LOADING', payload: false });
    }, [state.approvalScreenshot, title]);

    const handleCancelScreenshot = useCallback(() => {
        dispatch({ type: 'SET_APPROVAL_DIALOG_VISIBLE', payload: false });
    }, []);

    const handleApprovalDialogTransitionEnd = useCallback(() => {
        if (!state.isApprovalDialogVisible) {
            dispatch({ type: 'SET_APPROVAL_SCREENSHOT', payload: null });
        }
    }, [state.isApprovalDialogVisible]);

    const handleCamError = useCallback((error) => {
        if (typeof error === 'string') {
            dispatch({ type: 'SET_CAM_ERROR', payload: error });
        } else {
            dispatch({ type: 'SET_CAM_ERROR', payload: error.message });

        }
    }, []);

    const handleIsCamReady = useCallback((stream) => {
        try {
            const { deviceId } = stream.getVideoTracks()[0].getSettings();
            dispatch({ type: 'SET_CAM_DEVICE_ID', payload: deviceId });
        } catch (error) { }
        dispatch({ type: 'SET_CAM_ERROR', payload: null });
        dispatch({ type: 'SET_CAM_READY', payload: true });
    }, []);

    const handleDeviceChange = (event: SelectChangeEvent) => {
        const newDeviceId = event.target.value;
        dispatch({ type: 'SET_CAM_DEVICE_ID', payload: newDeviceId });

    }

    return (<Dialog
        sx={{ zIndex: theme => theme.zIndex.modal + 2 }}
        fullScreen
        open
        data-testid={JestTestId.FormElementCamScanDialogContainer}
        onClose={handleClose}
        aria-labelledby={`cam-capture-dialog`}>
        <AppBar sx={{ position: 'relative', backgroundColor: 'primary.dark' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
                {(devices.length > 1 && state.deviceId) && <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <FormControl
                        fullWidth={!mdUp}
                        sx={{
                            minWidth: '200px',
                            mt: .5,
                            '.MuiSelect-select': {
                                color: 'white'
                            }
                        }}>
                        <InputLabel
                            shrink={!!state.deviceId}
                            id="devices-list-label"
                            sx={{ color: 'white' }}>Camera</InputLabel>
                        <Select
                            MenuProps={{
                                sx: { zIndex: theme => theme.zIndex.modal + 3 }
                            }}
                            labelId="devices-list-label"
                            id="devices-list"
                            value={String(state.deviceId)}
                            label="Camera"
                            size="small"
                            onChange={handleDeviceChange}>
                            {devices.map((device) => <MenuItem color="white" key={device.deviceId} value={device.deviceId}>
                                {device.label}
                            </MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>}
            </Toolbar>
        </AppBar>
        <Box sx={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: `calc(100% - ${mdUp ? 64 : 56}px)`,
            width: '100%',
            backgroundColor: '#000',
            'video': {
                display: !state.isCamReady ? 'none' : 'block'
            }
        }}>
            {state.camError && <Box>
                <Typography color='error'>
                    {state.camError}
                </Typography>
            </Box>}
            <Webcam
                audio={false}
                forceScreenshotSourceSize
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    ...(isPortrait ? portraitConstraints : landscapeConstraints),
                    deviceId: state.deviceId,
                    facingMode: "environment"
                }}
                ref={webcamRef}
                onUserMedia={handleIsCamReady}
                onUserMediaError={handleCamError}
                screenshotQuality={1}
                style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%'
                }} />
            <Fab
                disabled={!state.isCamReady || state.isLoading || state.isUploading}
                onClick={handleCaptureScreenshot}
                sx={{
                    position: 'absolute',
                    bottom: 24,
                    left: '50%',
                    width: 70,
                    height: 70,
                    marginLeft: '-35px'
                }} color='primary'>
                {state.isLoading ? <CircularProgress /> : <Camera fontSize="large" />}
            </Fab>

            <ScreenshotApprovalDialog
                onTransitionEnd={handleApprovalDialogTransitionEnd}
                screenshotSrc={state.approvalScreenshot}
                isLoading={state.isLoading}
                onCancelScreenshot={handleCancelScreenshot}
                onApproveScreenshot={handleApproveScreenshot}
                isOpen={state.isApprovalDialogVisible} />
            {state.screenshots.length > 0 && <Fab
                disabled={state.isUploading}
                color='secondary'
                onClick={handleGeneratePdf}
                sx={{
                    position: 'absolute',
                    width: 70,
                    height: 70,
                    bottom: 24,
                    right: 30
                }}>
                {state.isUploading ? <CircularProgress /> : <Upload />}
            </Fab>}

            <Box sx={{ position: 'absolute', bottom: 10, left: 5, lineHeight: 0 }}>
                {state.screenshots.map((imageSrc, index) => (
                    <Card key={index}
                        sx={{
                            borderRadius: 0,
                            position: 'absolute',
                            bottom: index * 4,
                            width: 120,
                            left: 0,
                            pb: '1px',
                            lineHeight: 0
                        }}>
                        <Box
                            component='img'
                            src={imageSrc}
                            alt={`screenshot-${index}`}
                            sx={{
                                objectFit: 'cover',
                                height: 'auto',
                                width: '100%'
                            }} />
                    </Card>
                ))}
            </Box>
        </Box>
    </Dialog>)
}

type ScreenshotApprovalDialogProps = {
    isOpen: boolean;
    onApproveScreenshot: () => void;
    onCancelScreenshot: () => void;
    onTransitionEnd?: (event: TransitionEvent) => void;
    screenshotSrc: string;
    isLoading?: boolean;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ScreenshotApprovalDialog = ({ isLoading, isOpen, onCancelScreenshot, onApproveScreenshot, screenshotSrc, onTransitionEnd }: ScreenshotApprovalDialogProps) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

    return (
        <Dialog
            open={isOpen}
            fullScreen={!mdUp}
            onTransitionEndCapture={onTransitionEnd}
            TransitionComponent={Transition}
            sx={{
                zIndex: theme => theme.zIndex.modal + 3,
                '.MuiPaper-root': {
                    backgroundColor: '#000',
                    overflow: 'visible',
                },
            }}>
            <Box sx={{ lineHeight: 0, alignItems: 'center', display: 'flex', justifyContent: 'center', flex: 1 }}>
                <Box component='img' maxWidth='100%' src={screenshotSrc} sx={{ width: '100%' }} />
                <Box sx={{ width: '100%', position: 'absolute', bottom: 20, display: 'flex', justifyContent: 'space-around' }}>
                    <Fab
                        onClick={onCancelScreenshot}
                        sx={{ backgroundColor: 'error.main', color: 'white' }}>
                        <Cancel />
                    </Fab>
                    <Fab
                        onClick={onApproveScreenshot}
                        disabled={isLoading}
                        sx={{ backgroundColor: 'success.main', color: 'white' }}>
                        {isLoading ? <CircularProgress /> : <Check />}
                    </Fab>
                </Box>
            </Box>
        </Dialog>)
};

const useMediaDevices = (): { devices: MediaDeviceInfo[], isPortrait: boolean } => {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const filteredDevices = getFormattedDevicesList(mediaDevices);
            setDevices(filteredDevices);
        },
        [setDevices]
    );
    useEffect(
        () => {
            if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
                navigator.mediaDevices.enumerateDevices().then(handleDevices);
            }
        },
        [handleDevices]
    );

    const { innerWidth: windowWidth, innerHeight: windowHeight } = typeof window !== 'undefined' ? window : { innerHeight: 0, innerWidth: 0 };
    const isPortrait = windowHeight > windowWidth;


    return { devices, isPortrait };
}