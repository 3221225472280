import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useRef, useState } from 'react'
import type { FileRejection } from 'react-dropzone';
import { api } from 'src/api';
import { Card, USDriversLicense } from 'src/backend';
import { CardStatus } from 'src/constants/loan';
import { HEIC_MIME_TYPE, IMAGE_MIME_TYPES, PDF_MIME_TYPES } from 'src/constants/mimes';
import { DriverLicenseImageType } from 'src/constants/person';
import { ActionStatus } from 'src/constants/ui';
import { useCodeReader } from 'src/hooks/use-code-reader'
import { useLicenseKeys } from 'src/hooks/use-license-keys';
import { useMounted } from 'src/hooks/use-mounted';
import { Check } from 'src/icons/check';
import {
    driverLicenseImagesSelector,
    getDriverLicenseImages,
    postPersonDriverLicenseInfo,
    setUploadDriverLicenseIdleState,
    setUploadDriverLicenseLoadingState,
    uploadDriverLicense
} from 'src/slices/person';
import { showSnackbar } from 'src/slices/snackbar';
import { applicantSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { themeV2 } from 'src/theme/mysherpas-theme-option';
import { filterInvalidFiles, showRejectedDroppedFilesToast } from 'src/utils';
import { fileToDriverLicenseImage } from 'src/utils/file-to-driver-license-image';

import { DriverLicenseDropZone } from './upload-driver-license-form/driver-license-dropzone';

const UploadDriversLicenseForm = dynamic(() => import('src/components/applicant/upload-driver-license-form/upload-driver-license-form'));

interface PropsType extends Pick<Card<any>, 'status'> {
    onGoToNext?: () => void;
}

export const UploadDriverLicense = ({ status, onGoToNext }: PropsType) => {
    const webViewerReference = useRef(null);
    const [isPostingDriverLicense, setIsPostingDriveLicense] = useState<boolean>(false);
    // react state
    const [licenseInfo, setLicenseInfo] = useState<USDriversLicense>({} as USDriversLicense)
    // redux state
    const applicant = useSelector(applicantSelector)
    const isMounted = useMounted();
    const { pdftronKey } = useLicenseKeys();
    const personId = applicant?.id
    const hasLicenseInfo = licenseInfo && Object.keys(licenseInfo).length > 0
    const dispatch = useDispatch()

    const driverLicenseImages = useSelector(driverLicenseImagesSelector)

    const { decode, codeReader, decodeOnProgress } = useCodeReader()

    const theme = useTheme();

    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleDropFiles = async (type: keyof typeof DriverLicenseImageType, newFiles: File[], rejectedFiles: FileRejection[] = []): Promise<void> => {
        const { acceptedFiles: filteredAcceptedFiles, rejectedFiles: filteredRejectedFiles } = await filterInvalidFiles(newFiles, rejectedFiles);
        showRejectedDroppedFilesToast(filteredRejectedFiles);
        if (filteredAcceptedFiles.length === 0) {
            return;
        }
        dispatch(setUploadDriverLicenseLoadingState(type));
        const file = await fileToDriverLicenseImage(filteredAcceptedFiles[0], type, pdftronKey);
        if (IMAGE_MIME_TYPES.includes(file.type as any)) {
            await handleReadDriverLicenseBarcode(file);
            dispatch(uploadDriverLicense({ personId, file: file, type }));
        } else {
            dispatch(setUploadDriverLicenseIdleState(type));
        }

    };

    const handlePostEmptyDriverLicenseInfo = () => {
        dispatch(postPersonDriverLicenseInfo(personId, {
            address: null,
            backUrl: '',
            birthDate: '',
            dd: '',
            vehicleClass: '',
            expiration: '',
            eyesColor: '',
            familyName: '',
            frontUrl: '',
            givenName: '',
            hairColor: '',
            height: '',
            number: '',
            restrictions: '',
            state: null,
            verified: '',
            weight: '',
            createdBy: null,
            createdDate: null,
            lastModifiedBy: null,
            lastModifiedDate: null,
            revisionDate: '',
            complianceType: '',
            countryIdentification: '',
            middleName: '',
            documentDiscriminator: '',
            issueDate: '',
            givenNameTruncation: '',
            familyNameTruncation: '',
            middleNameTruncation: '',
            inventoryControlNumber: '',
            endorsementCodes: '',
            organDonorIndicator: '',
            sex: '',
            id: null
        }));
    }

    const handleReadDriverLicenseBarcode = useCallback(async (file: File | string): Promise<boolean> => {
        setIsPostingDriveLicense(true);
        const driversLicense = await decode(file);
        if (!!driversLicense) {
            setLicenseInfo(driversLicense);
            dispatch(showSnackbar({ text: 'Driver license details extracted from card successfully!', severity: 'success' }));
            return true;
        }
        return false;
    }, [decode, dispatch]);

    useEffect(() => {
        if (isMounted()) {
            dispatch(getDriverLicenseImages(personId));
            (async () => {
                const individual = await api.getIndividualFromPerson(personId, { id: personId });
                if (individual) {
                    let individualDl = null
                    try {
                        individualDl = await api.getADriversLicense(individual.id);
                    } catch (e) { }
                    setLicenseInfo({
                        ...individualDl,
                        birthDate: applicant?.birthDate ?? "",
                        address: individualDl?.residentialAddress ? individualDl?.residentialAddress : null
                    } as unknown as USDriversLicense);

                }
            })()
        }
    }, [dispatch, personId, isMounted, applicant?.birthDate])

    useEffect(() => {
        if (isMounted() && driverLicenseImages.back && codeReader && status !== CardStatus.PROCESSED && !isPostingDriverLicense) {
            setIsPostingDriveLicense(true);
            handleReadDriverLicenseBarcode(driverLicenseImages.back);
        }
    }, [
        isMounted,
        codeReader,
        driverLicenseImages.back,
        handleReadDriverLicenseBarcode,
        status,
        isPostingDriverLicense,
        driverLicenseImages.front
    ])

    const isFrontLoading = (driverLicenseImages.frontUploadStatus === ActionStatus.loading || driverLicenseImages.frontStatus === ActionStatus.loading)
    const isBackLoading = (driverLicenseImages.backUploadStatus === ActionStatus.loading || driverLicenseImages.backStatus === ActionStatus.loading)

    const hasAValidDriversLicense = licenseInfo?.number;

    return <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
            <div className='webviewer' ref={webViewerReference} style={{ display: 'none', visibility: 'hidden' }} />
            <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800], mb: 2 }}>
                Drivers License
            </Typography>
            <DriverLicenseDropZone
                onDrop={handleDropFiles.bind(null, DriverLicenseImageType.front)}
                accept={[...IMAGE_MIME_TYPES, ...PDF_MIME_TYPES, HEIC_MIME_TYPE]}
                disabled={isFrontLoading}
                uploaded={!!driverLicenseImages.front}
                imageSrc={driverLicenseImages.front}
                uploading={isFrontLoading}
                title={!!driverLicenseImages.front ? 'Front' : 'Upload Front'}
                hasError={false} />
            <Box sx={{ my: 3 }}>
                {driverLicenseImages.frontUploadStatus === ActionStatus.error &&
                    <Alert severity='error'>
                        <AlertTitle>Uploading Error</AlertTitle>
                        An error happened while uploading the file
                    </Alert>
                }
                {driverLicenseImages.back && !driverLicenseImages.front && decodeOnProgress && !isFrontLoading &&
                    <Alert severity='error'>
                        <AlertTitle>Driver license front</AlertTitle>
                        Please provide an image of the front side of your driving license
                    </Alert>
                }
            </Box>
            <DriverLicenseDropZone
                onDrop={handleDropFiles.bind(null, DriverLicenseImageType.back)}
                accept={[...IMAGE_MIME_TYPES, ...PDF_MIME_TYPES, HEIC_MIME_TYPE]}
                disabled={isBackLoading}
                uploaded={!!driverLicenseImages.back}
                uploading={isBackLoading}
                imageSrc={driverLicenseImages.back}
                title={!!driverLicenseImages.back ? 'Back' : 'Upload Back'}
                hasError={false} />
            <Box sx={{ mt: 3 }}>
                {driverLicenseImages.backUploadStatus === ActionStatus.error &&
                    <Alert severity='error'>
                        <AlertTitle>Uploading Error</AlertTitle>
                        An error happened while uploading the file
                    </Alert>
                }
                {(driverLicenseImages.back && status !== CardStatus.PROCESSED && !decodeOnProgress && !hasLicenseInfo) &&
                    <Alert severity='error'>
                        <AlertTitle>Driver license back</AlertTitle>
                        Could not read the back of your Identification, upload a clearer image, or enter your information manually.
                    </Alert>
                }
            </Box>
            {!hasAValidDriversLicense && <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800] }}>
                Drag and drop <span style={{ color: themeV2.neutral.grey[100] }}>or</span> select card to upload from browser
            </Typography>}
            {hasAValidDriversLicense && <Typography variant='subtitle2' sx={{ color: themeV2.colors.green[50], display: 'flex', alignItems: 'center', gap: 1 }}>
                <Check fontSize="small" />  Verified
            </Typography>}
            <Box sx={{
                mt: 3,
                justifyContent: {
                    xs: 'center',
                    sm: 'flex-end'
                },
                display: 'flex'
            }}>
                {(driverLicenseImages.back && status !== CardStatus.PROCESSED && !decodeOnProgress && !hasLicenseInfo) &&
                    <Button

                        color='warning'
                        fullWidth={!isSmAndUp}
                        variant='contained'
                        sx={{ boxShadow: 'none', ml: 2 }}
                        onClick={handlePostEmptyDriverLicenseInfo}>
                        Continue anyway
                    </Button>}
            </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
            <Typography variant='subtitle2' sx={{ color: themeV2.colors.blue[800], mb: 2 }}>
                Personal Information
            </Typography>
            {/* @ts-expect-error */}
            <UploadDriversLicenseForm
                onSubmitted={onGoToNext}
                status={status}
                defaultValues={licenseInfo} />
        </Grid>
    </Grid>

}