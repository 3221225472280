export const driversLicenseInfo = {
    mock: [
        {
            "filename": "bankhead_rickey_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ5214367\nDCSBANKHEAD\nDDEN\nDACRICKEY\nDDFN\nDADD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD10012019\nDBB10081968\nDBA10082024\nDBC1\nDAU071 in\nDAYBLU\nDAG402 CANYON OAKS WY\nDAISALT LAKE CITY\nDAJUT\nDAK841032281  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "connolly_john_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ75845621\nDCSCONNOLLY\nDDEN\nDACJOHN\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09162018\nDBB09161986\nDBA09162027\nDBC1\nDAU071 in\nDAYBLU\nDAG189 ASHCREEK DR\nDAITOWUERVILLE\nDAJUT\nDAK847745074  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "debari_angelantoni_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ817917717\nDCSDEBARI\nDDEN\nDACANGELANTONI\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD05202019\nDBB12021964\nDBA04232025\nDBC1\nDAU071 in\nDAYBLU\nDAG54 PO BOX 54\nDAICLEARFIELD\nDAJUT\nDAK840890054  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "lawless_john_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ123654456\nDCSLAWLESS\nDDEN\nDACJOHN\nDDFN\nDADA\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12082018\nDBB12081978\nDBA12082023\nDBC1\nDAU071 in\nDAYBLU\nDAG14260 CARROLL BRIDGE RD\nDAISMITHFIELD\nDAJVA\nDAK234307201  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "lyon_richard_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ3541236\nDCSLYON\nDDEN\nDACRICHARD\nDDFN\nDADB\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD03292019\nDBB01051961\nDBA04282025\nDBC1\nDAU071 in\nDAYBLU\nDAG10496 Silver Mountain Dr Apt. D\nDAISandy\nDAJUT\nDAK840944453  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "nations_steve_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ7568458951\nDCSNATIONS\nDDEN\nDACSTEVE\nDDFN\nDADG\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11292019\nDBB04301974\nDBA12282025\nDBC1\nDAU071 in\nDAYBLU\nDAG2284 W 5950 S\nDAIRoy\nDAJUT\nDAK840671439  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "raymond_kyle_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ3332211\nDCSRAYMOND\nDDEN\nDACKYLE\nDDFN\nDADJ\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD12012020\nDBB12011985\nDBA12012026\nDBC1\nDAU071 in\nDAYBLU\nDAG2581 BRIDLE OAK DR\nDAISOUTH JORDAN\nDAJUT\nDAK840953483  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "terwey_denise_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ666444222\nDCSTERWEY\nDDEN\nDACDENISE\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11292020\nDBB11171990\nDBA12282026\nDBC1\nDAU071 in\nDAYBLU\nDAG337 BAKER LN\nDAIGRANTSVILLE\nDAJUT\nDAK840290000  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "uphold_robert_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ666555444\nDCSUPHOLD\nDDEN\nDACROBERT\nDDFN\nDADE\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD09292017\nDBB10051954\nDBA10052022\nDBC1\nDAU071 in\nDAYBLU\nDAG27366 PERRY ST\nDAIROSEVILLE\nDAJMI\nDAK480662743  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "wolford_tina_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQ817971717\nDCSWOLFORD\nDDEN\nDACTINA\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD02292020\nDBB12011989\nDBA03282026\nDBC1\nDAU071 in\nDAYBLU\nDAG151 S 100 W\nDAIWASHINGTON\nDAJUT\nDAK847801912  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "abreu_natalie_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQTL545796\nDCSABREU\nDDEN\nDACNATALIE\nDDFN\nDAD\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD11122020\nDBB11121978\nDBA11122025\nDBC1\nDAU071 in\nDAYBLU\nDAG806 VERNON RD\nDAICOLUMBUS\nDAJOH\nDAK432092465  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
        {
            "filename": "worner_wayne_DL_BACK.jpg",
            "barcodeText": "@\n\u001e\rANSI 636040080002DL00410259ZU03000011DLDAQC7364290\nDCSWORNER\nDDEN\nDACWAYNE\nDDFN\nDADM\nDDGN\nDCAD\nDCBB\nDCDNONE\nDBD07272019\nDBB07271980\nDBA07272024\nDBC1\nDAU071 in\nDAYBLU\nDAG6906 LENWOOD WY\nDAISAN JOSE\nDAJCA\nDAK951203132  \nDCF66252181\nDCGUSA\nDAW165\nDAZBRO\nDCK190993584UTQXSL01\nDDAF\nDDB04152016\nDDK1\rZUZUA\nZUBC\r"
        },
    ]
}
