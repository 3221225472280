import { licenseKeysSelector } from "src/slices/license-keys";
import { useSelector } from "src/store";

interface ReturnValue {
    googleMapsKey: string;
    pdftronKey: string;
    syncfusionKey: string;
}

export const useLicenseKeys = (): ReturnValue => {

    const keys = useSelector(licenseKeysSelector);

    return {
        googleMapsKey: keys.googleMapsKey,
        pdftronKey: keys.pdftronKey,
        syncfusionKey: keys.syncfusionKey,
    }
};